@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
	position: relative;
	height: 100%;
	overflow: 'hidden';
  font-family: 'Roboto', Arial, Helvetica, sans-serif; 
  color: #231F20;
  margin: 0;
  padding: 0;

}

.MuiDialog-paperFullWidth {
  margin: 0px !important;
  width: 100%;
}

.dark#root {
    background-color:#231F20;
    color: #F2F2F2;
}
.dark.leaflet-tooltip {
    background-color:#313131;
    color: #F2F2F2;
}

.scrollingDiv div {
  background-image: none;
}

@media (prefers-color-scheme: dark)  {
  html {
    background-color:#231F20;
  }
}

h2, h1 {
	font-size: 1em;
	margin: 0px;
}


#root {
	height: 100%;
	position: relative;
  letter-spacing: 0.25px;
}

.bar {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  transition: width 500ms;
}

.MuiAccordionSummary-content, .Mui-expanded {
  margin: 5px 0px !important;
  min-height: 0px !important;
}
.leaflet-control-attribution a {
  display: flex;
  align-items: center;
  gap: 1px;
}

.leaflet-bar a {
  border-bottom: none;
}

.leaflet-tooltip {
  padding: 8px 12px;
  opacity: 1;
  border-radius: 2px;
  border: 1px #DBD9D9;
  box-shadow: 0px 4px 8px 0px #00000012;
  letter-spacing: 0.5px;
}
.leaflet-tooltip::before {
  border: 0;
}

td {
  padding: 0 !important;
  letter-spacing: 0.25px !important;
}
td+td {
  padding-left: 10px !important;
}

tr:last-child td{
  border-bottom: none;
}

#filteredCandidate + #filteredCandidate {
  border-top: 1px solid black;
}

.MuiOutlinedInput-notchedOutline {
  border-radius: 2px !important;
}

.MuiAutocomplete-clearIndicator {
  visibility: visible !important;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

::-webkit-scrollbar-track {
	border-radius: 10px
}

::-webkit-scrollbar-thumb {
	background: rgb(192, 192, 192);
}

::-webkit-scrollbar-thumb:hover {
	background: rgb(104, 104, 104);; 
  }

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    }
    .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid rgb(187, 187, 187);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgb(187, 187, 187) transparent transparent transparent;
    }
    .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
    }
    @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .fade-in-image {
    animation: fadeIn 500ms;
    -webkit-animation: fadeIn 500ms;
    -moz-animation: fadeIn 500ms;
    -o-animation: fadeIn 500ms;
    -ms-animation: fadeIn 500ms;
    visibility: visible !important;
    }
    .hide {
      opacity: 0;
    }
  
    @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
    
    @-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }